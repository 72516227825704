import styled from 'styled-components';

export const Header = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
        display: none;
        gap: 20px;
        justify-content: space-between;
        width: fit-content;

        @media (max-width: 1200px) {
            display: flex;
        }

        > button {
            font-size: 33px;
            color: var(--light);
            transition: color .2s ease-in;

            &:hover {
                color: var(--primary);
            }
        }
    }
`;

export const Slider = styled.div`
    --gap: 24px;
    --columns: 5;
    display: flex;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    gap: var(--gap);
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Title = styled.h3`
    font-size: 22px;
    font-weight: 700;
    color: #29333e;
    margin: .9em 0 .9em;
    padding-right: 2em;

    a {
        font-size: 14px;
        font-weight: 400;
        float: right;
        color: #3996fd;
        margin: 4px 0 0;
    }

    @media (max-width: 767px) {  
        font-size: 18px;
    }
`;

export const Productslider = styled.div`
    width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));

    @media (max-width: 1220px) {
        --columns: 4;
    }

    @media (max-width: 950px) {
        --columns: 3;
    }

    @media (max-width: 640px) {
        --columns: 2;
    }

    @media (max-width: 480px) {
        --columns: 1;
    }
`;
