import { useState, createRef, useRef } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { underLocale } from '@src/utils';
import * as Styles from './productlist.styled';

const ProductBlock = dynamic(() => import('../ProductBlock'), { ssr: true });

const ProductsList = ({ products, title, type = '' }) => {
    const [index, setIndex] = useState(0);
    const { locale } = useRouter();
    const slider = useRef();

    const data = Array.isArray(products) ? products : (products?.hits || []);
    const refs = data.map(() => createRef());

    function handleNavigation(direction) {
        return () => {
            const mappingDirection = {
                prev: index - 1,
                next: index + 1,
            };

            const ref = refs[mappingDirection[direction]]?.current;
            const maxScroll = slider.current.scrollWidth - slider.current.clientWidth;

            if (direction === 'prev' && index === 0) {
                setIndex(0);
                return false;
            }

            if (direction === 'next' && slider.current.scrollLeft === maxScroll) {
                return false;
            }

            if (ref) {
                ref.parentNode.scrollLeft = ref.offsetLeft;
            }

            setIndex(mappingDirection[direction]);

            return true;
        };
    }

    return (
        <div>
            <Styles.Header>
                <Styles.Title>{title}</Styles.Title>
                <div className="arrow">
                    <button type="button" className={`prevButton prevButton-${type} i-chevron-left`} aria-label="Previous" onClick={handleNavigation('prev')} />
                    <button type="button" className={`nextButton nextButton-${type} i-chevron-right`} aria-label="Next" onClick={handleNavigation('next')} />
                </div>
            </Styles.Header>
            <footer className="productsList p-0">
                <Styles.Slider ref={slider}>
                    {
                        data.map((product, i) => (
                            <Styles.Productslider key={product.id} ref={refs[i]}>
                                <ProductBlock
                                    className={product.id}
                                    product={product.configurations}
                                    model={product.slug[underLocale(locale)]}
                                />
                            </Styles.Productslider>
                        ))
                    }
                </Styles.Slider>
            </footer>
        </div>
    );
};

export default ProductsList;
